import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import {doAsync} from './async-util'
import * as types from './mutation-types'
import {setAxiosAuthToken} from "@/utils";

import localforage from 'localforage'
import {
  CLONE_CANDY_BOX,
  CLONE_FAZER_BOX,
  CLONE_MARIANNE_BOX, GET_CALENDAR_DOMAIN,
  LIST_FREIGHT_PRICES,
  SPECIAL_ORDER_FORM
} from "./mutation-types";

const local_store = localforage.createInstance({
  name: 'dominostore',
})

const vuexPersist = new VuexPersistence({
  storage: local_store,
  reducer: (state) => {
    return JSON.parse(JSON.stringify(state))
  },
  asyncStorage: true,
})

let state_dict = {
  reseting: false,
  products_data: {
    domino: {
      product_id: null,
      editor: {
        image: {
          croppie: {
            url: null,
            points: null,
            zoom: null,
          },
          base64: null,
          image_orientation: null,
        },
      },
      text: {
        font: 'F_GOTHAM',
        noText: false,
        message: '',
      },
      croppied_logo_url: null,
      product_thumbnail: null
    },
    calendar: {
      product_id: null,
      editor: {
        image: {
          croppie: {
            url: null,
            points: null,
            zoom: null,
          },
          base64: null,
        },
      },
      product_thumbnail: null
    },
    calendar_b2b: {
      product_id: null,
      editor: {
        image: {
          croppie: {
            url: null,
            points: null,
            zoom: null,
          },
          base64: null,
        },
      },
      product_thumbnail: null
    },
    fazer: {
      product_id: null,
      editor: {
        text: '',
        signature: '',
        title: ''
      },
      product_thumbnail: null,
    },
    fazer_bar: {
      product_id: null,
      editor: {
        text: '',
        signature: '',
        title: ''
      },
      product_thumbnail: null,
    },
    marianne: {
      product_id: null,
      editor: {
        text: '',
        signature: '',
        title: ''
      },
      product_thumbnail: null,
    },
    custom_candy_box: {
      product_id: null,
      editor: {
        text: '',
        signature: '',
        title: ''
      },
      product_thumbnail: null,
    },
  },
  order: {
    products: []
  },
  discount_volumes: [],
  shipping_price: null, // FIXME list?
  freight_price: [],
  landing_texts: [{'text': ''}],
  landing_titles: [{'title': ''}],
  voucher_discount: null,
  billing: false,
  temporal_contact_data: null,
  authenticated: false,
  is_company: false,
  policies: {},
  spinnerActive: false,
  cartSpinnerActive: false,
  notificationActive: false,
  notificationMessage: '',
  settings: [],
  calendarMode: false,
  orderDeliveryAddress: [],
  barCodeData: null
}

let store_config = {
  state() {
    return state_dict
  },
  mutations: {
    setReseting(state, reset) {
      state.reseting = reset
    },
    setOrderData(state, order) {
      state.order = order
    },
    setVolumesDiscount(state, data) {
      state.discount_volumes = data
    },
    setVoucherDiscount(state, data) {
      state.voucher_discount = data
    },
    clearVoucherDiscount(state) {
      state.voucher_discount = null
      state.useVoucherDiscountStatusCode = ''
    },
    setShippingPrice(state, data) {
      state.shipping_price = data.results[0]
    },
    setFreightPrice(state, data) {
      state.freight_price = data.results
    },
    setlistLandingTexts(state, data) {
      state.landing_texts = data.results
    },
    setlistLandingTitles(state, data) {
      state.landing_titles = data.results
    },
    setPolicies(state, data) {
      state.policies = data
    },
    setSettings(state, data) {
      state.settings = data.results
    },
    setDomainCalendar(state, data) {
      state.calendarMode = data['calendar']
    },
    setProducts(state, data) {
      data.results.forEach( function(product) {
        if (product.name == 'domino') {
           state.products_data.domino.product_thumbnail = product.thumbnail
           state.products_data.domino.product_id = product.id
        }
        else if (product.name == 'blue') {
          state.products_data.fazer.product_thumbnail = product.thumbnail
          state.products_data.fazer.product_id = product.id
        } else if (product.name == 'bar') {
          state.products_data.fazer_bar.product_thumbnail = product.thumbnail
          state.products_data.fazer_bar.product_id = product.id
        } else if (product.name == 'marianne') {
          state.products_data.marianne.product_thumbnail = product.thumbnail
          state.products_data.marianne.product_id = product.id
        } else if (product.name == 'custom-candy-box') {
          state.products_data.custom_candy_box.product_thumbnail = product.thumbnail
          state.products_data.custom_candy_box.product_id = product.id
        } else if (product.name == 'calendar') {
          state.products_data.calendar.product_thumbnail = product.thumbnail
          state.products_data.calendar.product_id = product.id
        } else if (product.name == 'calendar-b2b') {
          state.products_data.calendar_b2b.product_thumbnail = product.thumbnail
          state.products_data.calendar_b2b.product_id = product.id
        }
      })
    },
    resetData(state) {
      state.order = {
        croppied_logo_url: null,
        products: []
      }
      state.postImagesData = null
      state.postFazerBoxData = null
      state.postMarianneBoxData = null
      state.postFazerBarData = null
      state.postLoginData = null
      state.postContactData = null
      state.putContactData = null
      state.partialUpdateOrderData = null
      state.removeBoxData = null
      state.updateOrderData = null
      state.voucher_discount = null
      state.billing = false
      state.useVoucherDiscountStatusCode = ''
      state.temporal_contact_data = null
      state.authenticated = false
      state.discount_volumes = []
      state.shipping_price = null
      state.freight_price = []
      state.landing_texts = []
      state.landing_titles = []
      state.policies = {}
      state.notificationActive = false
      state.notificationMessage = ''
      state.orderDeliveryAddress = []
      state.barCodeData = null
    },
    checkSessionValidation(state, data) {
      state.order = data.order
      // invalidate store order if it expired to dont get into infinite bucle crash
      if (state.CHECK_EXPIRATION_SESSION_FAILURE != null && !state.CHECK_EXPIRATION_SESSION_FAILURE.valid) {
        state.order =  {
          croppied_logo_url: null,
          products: []
        }
      } else if (data.valid) {
        state.order = data.order
        setAxiosAuthToken(data.token)
      } else {
        state.order =  {
          croppied_logo_url: null,
          products: []
        }
      }
    },
    setIsCompany(state, isCompany) {
      state.is_company = isCompany
    },
    setSpinnerState(state, spinnerState) {
      state.spinnerActive = spinnerState
    },
    setCartSpinnerState(state, spinnerState) {
      state.cartSpinnerActive = spinnerState
    },
    setOrderDeliveryAddress(state, data) {
      state.orderDeliveryAddress = data
    },
    setNotificationActiveState(state, data) {
      state.notificationActive = data['notificationState']
      state.notificationMessage = data['message']
    },
    updateProductAmount(state, data) {
      let product_idx = state.order.products.findIndex((product => product.id == data['product_id']))
      state.order.products[product_idx].amount = data['amount']
    },
    updateProductAddress(state, data) {
      let product_idx = state.order.products.findIndex((product => product.id == data['product_id']))
      state.order.products[product_idx].delivery_address = data['address']
      state.order.products[product_idx].is_address_editable = data['is_address_editable']
    },
    setBarCodeData(state, data) {
      state.barCodeData = data
    },
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION, // this mutation **MUST** be named "RESTORE_MUTATION"
  },
  getters: {
    getReseting(state) {
      return state.reseting
    },
    getOrderData(state) {
      return state.order
    },
    getProductById: (state) => (id) => {
      if ('order' in state) return state.order.products.find((product) => product.id === id)
      return null
    },
    getMaintenanceSetting: (state) => {
      let setting = state.settings.find((setting) => setting.name === 'maintenance')
      if (setting == undefined) {
        return false
      }
      return setting
    },
    products: (state) => {
      return state.order.products
    },
    getContactData: (state) => {
      if (state.postContactData != null) {
        return state.postContactData
      } else {
        return state.temporal_contact_data
      }
    },
    getVolumesDiscount: (state) => (product) => {
      return state.discount_volumes.filter(prod => {
         return prod.product_name == product
      })
    },
    getShippingPrice: (state) => {
      return state.shipping_price
    },
    getFreightPricePerWeight: (state) => {
      return state.freight_price
    },
    getVoucherDiscount: (state) => {
      if (state.voucher_discount != null) {
        return state.voucher_discount
      }
      return null
    },
    getTotalAmount: (state) => (product) => {
      if (state.order == null) {
        return 0
      }
      let total = 0
      state.order.products.forEach(function(box) {
        if (box.product.name == product && box.draft == false) {
          total += box.amount
        }
      })
      return total
    },
    getVolumeDiscount: (state, getters) => (product) => {
      let discounted_value = null
      let discounts = getters.getVolumesDiscount(product)
      let total_pcs = getters.getTotalAmount(product)

      // it has to be greater than the minimum at least
      if (discounts.length > 0 && total_pcs <= discounts[0].volume_point) {
        discounted_value = discounts[0].price
      } else {
        // check ranges
        for (let i = 0; i < discounts.length; i++) {
          if (discounts[i + 1] !== undefined) {
            if (total_pcs >= discounts[i].volume_point && total_pcs < discounts[i + 1].volume_point) {
              discounted_value = discounts[i].price
              break
            }
          } else {
            discounted_value = discounts[discounts.length - 1].price
            break
          }
        }
      }
      return discounted_value
    },
    getTotalAmountProducts: (state, getters) => {
      let totalDominos = getters.getTotalAmount('domino') * getters.getVolumeDiscount("domino")
      let totalFazers = getters.getTotalAmount('blue') * getters.getVolumeDiscount("blue")
      let totalBars =  getters.getTotalAmount('bar') * getters.getVolumeDiscount("bar")
      let totalMarianne = getters.getTotalAmount('marianne') * getters.getVolumeDiscount("marianne")
      let totalCustomCandy = getters.getTotalAmount('custom-candy-box') * getters.getVolumeDiscount("custom-candy-box")
      let totalCalendar = getters.getTotalAmount('calendar') * getters.getVolumeDiscount("calendar")
      let totalCalendarB2b = getters.getTotalAmount('calendar-b2b') * getters.getVolumeDiscount("calendar-b2b")
      return totalDominos + totalFazers + totalBars + totalMarianne + totalCustomCandy + totalCalendar + totalCalendarB2b
    },
    getProductsLength: (state, getters) => {
      return getters.getTotalAmount('domino') +
          getters.getTotalAmount('blue') +
          getters.getTotalAmount('bar') +
          getters.getTotalAmount('marianne') +
          getters.getTotalAmount('custom-candy-box') +
          getters.getTotalAmount('calendar') +
          getters.getTotalAmount('calendar-b2b')
    },
    authToken: state => {
      return state.authenticated
    },
    getIsCompany: (state) => {
      return state.is_company
    },
    getMarketingPolicy(state){
      if ('FGCOM_MP' in state.policies) {
        return state.policies['FGCOM_MP']
      }
      return ''
    },
    getTermsPolicy(state){
      if ('FGWEB_DOF' in state.policies) {
        return state.policies['FGWEB_DOF']
      }
      return ''
    },
    getMyFazerPolicy(state){
      if ('FG_MYFAZER_TC' in state.policies) {
        return state.policies['FG_MYFAZER_TC']
      }
      return ''
    },
    getProductId: (state) => (product) => {
      if (product == 'domino') {
        return state.products_data.domino.product_id
      } else if (product == 'blue') {
        return state.products_data.fazer.product_id
      } else if (product == 'bar') {
        return state.products_data.fazer_bar.product_id
      } else if (product == 'marianne') {
        return state.products_data.marianne.product_id
      } else if (product == 'custom-candy-box') {
        return state.products_data.custom_candy_box.product_id
      } else if (product == 'calendar') {
        return state.products_data.calendar.product_id
      } else if (product == 'calendar-b2b') {
        return state.products_data.calendar_b2b.product_id
      }
    },
    getProductThumbnail: (state) => (product) => {
      if (product == 'domino') {
        return state.products_data.domino.product_thumbnail
      } else if (product == 'blue') {
        return state.products_data.fazer.product_thumbnail
      } else if (product == 'bar') {
        return state.products_data.fazer_bar.product_thumbnail
      } else if (product == 'marianne') {
        return state.products_data.marianne.product_thumbnail
      } else if (product == 'custom-candy-box') {
        return state.products_data.custom_candy_box.product_thumbnail
      } else if (product == 'calendar') {
        return state.products_data.calendar.product_thumbnail
      } else if (product == 'calendar-b2b') {
        return state.products_data.calendar_b2b.product_thumbnail
      }
    },
    getNotificationActive(state) {
      return state.notificationActive
    },
    getNotificationMessage(state) {
      return state.notificationMessage
    },
    getCartSpinnerState(state) {
      return state.cartSpinnerActive
    },
    getSavedItems: (state) => {
      if (state.order == null || state.order.products == undefined) {
        return []
      }
      return state.order.products.filter(el => el.draft === false)
    },
    getLandingTexts(state) {
      return state.landing_texts
    },
    getLandingTitles(state) {
      return state.landing_titles
    },
    getCalendarMode(state) {
      return state.calendarMode
    },
    getOrderDeliveryAddress(state) {
      return state.orderDeliveryAddress
    },
    getBarCodeData(state) {
      return state.barCodeData
    }
  },
  actions: {
    login(store) {
      ///here
      return doAsync(store, {
        url: 'login/',
        mutationTypes: types.POST_LOGIN,
        method: 'post',
      })
    },
    postImage(store, formData) {
      return doAsync(store, {
        url: 'image/',
        mutationTypes: types.POST_IMAGES,
        method: 'post',
        data: formData,
      }).then(() => {
        store.commit('setOrderData', store.state.postImagesData)
      })
    },
    postCalendar(store, formData) {
      return doAsync(store, {
        url: 'calendar/',
        mutationTypes: types.POST_CALENDAR,
        method: 'post',
        data: formData,
      }).then(() => {
        store.commit('setOrderData', store.state.postCalendarData)
      })
    },
    postFazerBox(store, formData) {
      return doAsync(store, {
        url: 'fazer-box/',
        mutationTypes: types.POST_FAZER_BOX,
        method: 'post',
        data: formData,
      }).then(() => {
        store.commit('setOrderData', store.state.postFazerBoxData)
      })
    },
    postFazerBar(store, formData) {
      return doAsync(store, {
        url: 'fazer-bar/',
        mutationTypes: types.POST_FAZER_BAR,
        method: 'post',
        data: formData,
      }).then(() => {
        store.commit('setOrderData', store.state.postFazerBarData)
      })
    },
    postMarianneBox(store, formData) {
      return doAsync(store, {
        url: 'marianne-box/',
        mutationTypes: types.POST_MARIANNE_BOX,
        method: 'post',
        data: formData,
      }).then(() => {
        store.commit('setOrderData', store.state.postMarianneBoxData)
      })
    },
    postCustomCandyBox(store, formData) {
      return doAsync(store, {
        url: 'custom-candy-box/',
        mutationTypes: types.POST_CUSTOM_CANDY_BOX,
        method: 'post',
        data: formData,
      }).then(() => {
        store.commit('setOrderData', store.state.postCustomCandyBoxData)
      })
    },
    getOrder(store) {
      return doAsync(store, {
        url: 'order/' + store.state.order.id + '/',
        mutationTypes: types.POST_IMAGES,
        method: 'get',
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(() => {
        store.commit('setOrderData', store.state.postImagesData)
      })
    },
    updateOrder(store, orderData) {
      let delivery_type = orderData['delivery_type']
      let products = orderData['products']
      let data = {
        id: store.state.order.id,
        products: products,
      }
      if (delivery_type != undefined) {
        data = {
          id: store.state.order.id,
          products: products,
          delivery_type: delivery_type
        }
      }
      return doAsync(store, {
        url: 'order/' + store.state.order.id + '/',
        mutationTypes: types.UPDATE_ORDER,
        method: 'put',
        data: data
      }).then(() => {
        store.commit('setOrderData', store.state.updateOrderData)
      })
    },
    partialUpdateOrder(store, orderData) {
      orderData['id'] = store.state.order.id
      return doAsync(store, {
        url: 'order/patch/',
        mutationTypes: types.PARTIAL_UPDATE_ORDER,
        method: 'patch',
        data: orderData,
      }).then(() => {
        store.commit('setOrderData', store.state.partialUpdateOrderData)
      })
    },
    partialUpdateBox(store, boxData) {
      return doAsync(store, {
        url: 'box/patch/',
        mutationTypes: types.PARTIAL_UPDATE_BOX,
        method: 'patch',
        data: boxData,
      }).then(() => {
        store.commit('setOrderData', store.state.partialUpdateBoxData)
      })
    },
    partialUpdateCustomCandyBox(store, customCandyBoxData) {
      return doAsync(store, {
        url: 'custom-candy-box/patch/',
        mutationTypes: types.PARTIAL_UPDATE_CUSTOM_CANDY_BOX,
        method: 'patch',
        data: customCandyBoxData,
      }).then(() => {
        store.commit('setOrderData', store.state.partialUpdateCustomCandyBoxData)
      })
    },
    cloneDominoBox(store, data) {
      return doAsync(store, {
        url: 'box/clone/',
        mutationTypes: types.CLONE_DOMINO_BOX,
        method: 'post',
        data: data,
      }).then(() => {
        store.commit('setOrderData', store.state.cloneDominoBoxData)
      })
    },
    cloneFazerBox(store, data) {
      return doAsync(store, {
        url: 'fazer-box/clone/',
        mutationTypes: types.CLONE_FAZER_BOX,
        method: 'post',
        data: data,
      }).then(() => {
        store.commit('setOrderData', store.state.cloneFazerBoxData)
      })
    },
    cloneFazerBar(store, data) {
      return doAsync(store, {
        url: 'fazer-bar/clone/',
        mutationTypes: types.CLONE_FAZER_BAR,
        method: 'post',
        data: data,
      }).then(() => {
        store.commit('setOrderData', store.state.cloneFazerBarData)
      })
    },
    cloneMarianneBox(store, data) {
      return doAsync(store, {
        url: 'marianne-box/clone/',
        mutationTypes: types.CLONE_MARIANNE_BOX,
        method: 'post',
        data: data,
      }).then(() => {
        store.commit('setOrderData', store.state.cloneMarianneBoxData)
      })
    },
    cloneCandyBox(store, data) {
      return doAsync(store, {
        url: 'custom-candy-box/clone/',
        mutationTypes: types.CLONE_CANDY_BOX,
        method: 'post',
        data: data,
      }).then(() => {
        store.commit('setOrderData', store.state.cloneCandyBoxData)
      })
    },
    cloneCalendarB2B(store, data) {
      return doAsync(store, {
        url: 'calendar/clone/',
        mutationTypes: types.CLONE_CALENDAR,
        method: 'post',
        data: data,
      }).then(() => {
        store.commit('setOrderData', store.state.cloneCalendarData)
      })
    },
    removeBox(store, product_id) {
      return doAsync(store, {
        url: `box/${product_id}/`,
        mutationTypes: types.REMOVE_BOX,
        method: 'delete',
      }).then(() => {
        store.commit('setOrderData', store.state.removeBoxData)
      })
    },
    removeBlueBox(store, product_id) {
      return doAsync(store, {
        url: `fazer-box/${product_id}/`,
        mutationTypes: types.REMOVE_BOX,
        method: 'delete',
      }).then(() => {
        store.commit('setOrderData', store.state.removeBoxData)
      })
    },
    removeBlueBar(store, product_id) {
      return doAsync(store, {
        url: `fazer-bar/${product_id}/`,
        mutationTypes: types.REMOVE_BOX,
        method: 'delete',
      }).then(() => {
        store.commit('setOrderData', store.state.removeBoxData)
      })
    },
    removeMarianneBox(store, product_id) {
      return doAsync(store, {
        url: `marianne-box/${product_id}/`,
        mutationTypes: types.REMOVE_BOX,
        method: 'delete',
      }).then(() => {
        store.commit('setOrderData', store.state.removeBoxData)
      })
    },
    removeCalendar(store, product_id) {
      return doAsync(store, {
        url: `calendar/${product_id}/`,
        mutationTypes: types.REMOVE_BOX,
        method: 'delete',
      }).then(() => {
        store.commit('setOrderData', store.state.removeBoxData)
      })
    },
    removeCandyBox(store, product_id) {
      return doAsync(store, {
        url: `custom-candy-box/${product_id}/`,
        mutationTypes: types.REMOVE_BOX,
        method: 'delete',
      }).then(() => {
        store.commit('setOrderData', store.state.removeBoxData)
      })
    },
    postContact(store, formData) {
      return doAsync(store, {
        url: 'contact/',
        mutationTypes: types.POST_CONTACT,
        method: 'post',
        data: formData,
      })
    },
    updateContact(store, formData) {
      return doAsync(store, {
        url: `contact/${store.state.postContactData.id}/`,
        mutationTypes: types.PUT_CONTACT,
        method: 'put',
        data: formData,
      })
    },
    getContact(store, orderId) {
      return doAsync(store, {
        url: `contact/${orderId}/`,
        mutationTypes: types.POST_CONTACT,
        method: 'get',
        //data: formData,
      })
    },
    postSpecialOrderForm(store, formData) {
      return doAsync(store, {
        url: 'special-order-form/',
        mutationTypes: types.SPECIAL_ORDER_FORM,
        method: 'post',
        data: formData,
      })
    },
    listDiscountVolumes(store) {
      return doAsync(store, {
        url: `volume-discount/`,
        mutationTypes: types.LIST_DISCOUNT_VOLUMES,
        method: 'get',
      }).then(() => {
        store.commit('setVolumesDiscount', store.state.listDiscountVolumesData)
      })
    },
    useVoucherDiscount(store, code) {
      return doAsync(store, {
        url: `voucher-discount/${code}`,
        mutationTypes: types.USE_VOUCHER_DISCOUNT,
        method: 'get',
      }).then(() => {
        if (store.state.useVoucherDiscountStatusCode == 200) {
          store.commit('setVoucherDiscount', store.state.useVoucherDiscountData)
        }
      })
    },
    getDeliveryAddress(store) {
      return doAsync(store, {
        url: `delivery-address/${store.state.order.id}`,
        mutationTypes: types.GET_DELIVERY_ADDRESS,
        method: 'get',
      }).then(() => {
        store.commit('setOrderDeliveryAddress', store.state.getDeliveryAddressData)
      })
    },
    listShippingPrices(store) {
      return doAsync(store, {
        url: `shipping-price/`,
        mutationTypes: types.LIST_SHIPPING_PRICES,
        method: 'get',
      }).then(() => {
        store.commit('setShippingPrice', store.state.listShippingPricesData)
      })
    },
    listFreightPrices(store) {
      return doAsync(store, {
        url: `freight-price/`,
        mutationTypes: types.LIST_FREIGHT_PRICES,
        method: 'get',
      }).then(() => {
        store.commit('setFreightPrice', store.state.listFreightPricesData)
      })
    },
    checkExpirationSession(store, data) {
      return doAsync(store, {
        url: `check-session/?product_id=${data.product_id}`,
        mutationTypes: types.CHECK_EXPIRATION_SESSION,
        method: 'get',
      }).then(() => {
        store.commit('checkSessionValidation', store.state.checkExpirationSessionData)
      }).catch(() => {
        store.commit('checkSessionValidation', store.state)
      })
    },
    createPaytrailSession(store) {
      return doAsync(store, {
        url: `order/${store.state.order.id}/create_paytrail_session/`,
        mutationTypes: types.PAYTRAIL_SESSION,
        method: 'post',
      })
    },
    listPolicies(store) {
      return doAsync(store, {
        url: `policies/`,
        mutationTypes: types.GET_POLICIES,
        method: 'get',
      }).then(() => {
        store.commit('setPolicies', store.state.getPoliciesData)
      })
    },
    getProducts(store) {
      return doAsync(store, {
        url: `products/`,
        mutationTypes: types.GET_PRODUCTS,
        method: 'get',
      }).then(() => {
        store.commit('setProducts', store.state.getProductsData)
      })
    },
    getSettings(store) {
      return doAsync(store, {
        url: `settings/`,
        mutationTypes: types.GET_SETTINGS,
        method: 'get',
      }).then(() => {
        store.commit('setSettings', store.state.getSettingsData)
      })
    },
    listLandingTexts(store) {
      return doAsync(store, {
        url: `landing-texts/`,
        mutationTypes: types.GET_MARKETING_LANDING_PAGE_TEXT,
        method: 'get',
      }).then(() => {
        store.commit('setlistLandingTexts', store.state.getMarketingLandingPageTextData)
      })
    },
    listLandingTitles(store) {
      return doAsync(store, {
        url: `landing-titles/`,
        mutationTypes: types.GET_MARKETING_LANDING_PAGE_TITLE,
        method: 'get',
      }).then(() => {
        store.commit('setlistLandingTitles', store.state.getMarketingLandingPageTitleData)
      })
    },
    domainCalendarCheck(store) {
      return doAsync(store, {
        url: `domain/`,
        mutationTypes: types.GET_CALENDAR_DOMAIN,
        method: 'get',
      }).then(() => {
        store.commit('setDomainCalendar', store.state.getCalendarDomainData)
      })
    },
    scanBarCode(store, data) {
      return doAsync(store, {
        url: 'bar-code/',
        mutationTypes: types.SCAN_BAR_CODE,
        method: 'post',
        data: data
      }).then(() => {
        store.commit('setBarCodeData', store.state.scanBarCodeData)
      })
    }
  },

  plugins: [vuexPersist.plugin],
}

/*
 * For each async mutation, dynamically add the three mutations:
 * SUCCESS - write the response to the store using the `stateKey` property
 * PENDING - set the `loadingKey` property in the store to true
 * FAILURE - Set `loadingKey` to false, and `errorCode` - for example 401, 404...
 *
 * stateKey, errorCode and loadingKey are prepended with the name of the action,
 * for example an action getData would lead to:
 *
 * mutations:
 *   GET_DATA_SUCCESS,
 *   GET_DATA_PENDING,
 *   GET_DATA_FAILURE
 *
 * state:
 *   getDataLoadingKey,
 *   getDataStateKey,
 *   getDataErrorCode
 *
 * For all mutations following defaults are applied:
 *   getDataLoadingKey = false
 *   getDataStateKey = null
 *   getDataStatusCode = null
 *   getDataErrorCode = null
 */
Object.keys(types).forEach((type) => {
  // add the initial state
  state_dict[types[type].loadingKey] = false
  state_dict[types[type].stateKey] = null
  state_dict[types[type].statusCode] = null
  state_dict[types[type].FAILURE] = null

  // add the mutation
  store_config['mutations'][types[type].BASE] = (state, payload) => {
    switch (payload.type) {
      case types[type].PENDING:
        state[types[type].loadingKey] = payload.value
        return state[types[type].loadingKey]

      case types[type].SUCCESS:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = null
        state[types[type].stateKey] = payload.data
        return state[types[type].stateKey]

      case types[type].FAILURE:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = payload.error
        return state[types[type].FAILURE]
    }
  }
})

const store = createStore(store_config)

export default store
