<template>
  <div class="rules">
    <SectionHeader></SectionHeader>
    <div class="section">
      <div class="container is-relative fix-position">
        <div class="columns">
          <div class="column is-full mb-6">
            <div class="content is-medium" v-if="!getCalendarMode()">
              <h1 class="has-text-centered">
                Custom Fazer -palvelun sopimus- ja toimitusehdot
              </h1>
              <p class="has-text-centered">
                Katso myös Fazerin verkkosivustojen yleiset
                <a href="https://www.fazergroup.com/fi/kayttoehdot/" target="_blank">käyttöehdot</a>,
                joita sovelletaan Custom Fazer -palvelun ja verkkosivuston käyttöön.
              </p>
              <ol>
                <li>
                  Yleistä
                  <ol>
                    <li>
                      Custom Fazer -palvelu on Fazer Makeiset Oy:n (jäljempänä “Fazer”) elintarvikkeiden
                      pakkaussuunnittelu- ja tilauspalvelu (jäljempänä ”Palvelu”).
                    </li>
                    <li>
                      Nämä sopimusehdot sisältävät Palvelun käyttöä koskevat ehdot sekä tilausta ja toimitusta koskevat
                      ehdot. Rekisteröityessään Palveluun tai ostaessaan tuotteita Palvelusta Palvelun käyttäjä (jäljempänä
                      ”Asiakas”) hyväksyy ehdot ja sitoutuu noudattamaan näitä ehtoja. Palvelussa on näitä ehtoja
                      tarkentavia ohjeita.
                    </li>
                    <li>
                      Palvelu on ensisijaisesti tarkoitettu yritys- ja yhdistysasiakkaille. Palvelua voi kuitenkin käyttää
                      myös yksityishenkilö, mutta vain y-tunnuksella tilatessa voi maksutapana olla lasku.
                      Minimitilausmäärä on riippuvainen tuotteesta.
                    </li>
                  </ol>
                </li>
                <li>
                  Henkilötiedot ja niiden käyttö
                  <ol>
                    <li>
                      Palvelua käyttävien Asiakkaiden henkilö- ja yritystiedot kerätään ja tallennetaan Fazerin
                      ylläpitämään asiakasrekisteriin. Henkilötietojen käyttö on kuvattu Fazerin <a href="https://www.fazergroup.com/fi/tietosuoja/tietosuojaseloste/" target="_blank">tietosuojaselosteessa</a>.
                      Henkilötietoja kerätään pääasiassa Palvelun toteuttamiseksi sekä tuotteiden toimittamiseksi.
                    </li>
                    <li>
                      Asiakkaalla on oikeus tarkistaa itseään koskevat henkilötiedot sekä pyytää muuttamaan niitä tai
                      poistamaan tiedot rekisteristä kokonaan.
                    </li>
                  </ol>
                </li>

                <li>
                  Tilaus
                  <ol>
                    <li>Itse suunnitellut tuotteet tilataan Custom Fazer -palvelun välityksellä.</li>
                    <li>
                      Tilaus tulee Fazeria sitovaksi, kun se on erikseen Fazerin toimesta vahvistettu. Fazer pidättää
                      itsellään oikeuden olla hyväksymättä tilausta esimerkiksi siinä tapauksessa, että tilaus on Fazerin
                      mielestä hyvien tapojen vastainen tai muuten sopimaton tai jos Fazerilla on syytä epäillä Asiakkaan
                      maksukykyä. Fazer voi käyttää tätä oikeutta myös tilausvahvistuksen toimittamisen jälkeen. Fazer
                      pyrkii ilmoittamaan Asiakkaalle mahdollisimman pian siinä tapauksessa, että Fazer hylkää tilauksen.
                      Asiakkaalla ei ole oikeutta peruuttaa tilausta sen jälkeen, kun tuotanto on aloitettu.
                    </li>
                  </ol>
                </li>
                <li>
                  Tuotehinnat
                  <ol>
                    <li>
                      Tuotteiden kulloinkin voimassa olevat hinnat näkyvät Custom Fazer -palvelun sivuilla.
                      Toimituskulut lisätään tilaukselle valitun toimitustavan mukaisesti. Tuotteiden hinnat ja
                      toimituskulut sisältävät arvonlisäveron 14%. Mikäli arvonlisäverokantaa muutetaan lainsäädännön tai
                      viranomaisohjeistuksen mukaisesti, on Fazerilla oikeus muuttaa arvonlisävero vastaamaan muuttunutta
                      verokantaa sen voimaantulopäivästä.
                    </li>
                  </ol>
                </li>
                <li>
                  Maksaminen
                  <ol>
                    <li>
                      Asiakas voi maksaa verkkokaupassamme yleisimmillä verkkomaksutavoilla, kuten pankkimaksuilla sekä
                      luottokortilla (Visa, Mastercard). Yritystilaukset voi maksaa myös laskulla (huom. vaaditaan y-tunnus).
                      Kaikki hinnat sisältävät ALV:n ja käytettynä valuuttana on euro.
                    </li>
                    <li>
                      Verkkomaksutavoilla maksaessa maksupalvelutarjoajana toimii Paytrail. Paytrail on turvallinen ja helppo
                      maksutapa. Kun valitset maksutavaksi ”Maksa verkossa”, sinut ohjataan Paytrail-maksuseinälle, mistä sinut
                      ohjataan onnistuneen maksusuorituksen jälkeen takaisin Custom Fazer -palveluun. Kun maksat Paytrail
                      tarjoamilla maksutavoilla, hyväksyt Paytrailin ehdot.
                      <p style="margin-top: 15px;">
                      Maksupalvelutarjoaja
                      </p>
                      <p style="margin-top: 15px;">
                      Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii Paytrail Oyj (2122839-7) yhteistyössä
                      suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella tai
                      korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on maksulaitoksen toimilupa.
                      Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä tuotteen toimittajaan.
                      </p>

                      <p style="margin-top: 15px;">Paytrail Oyj, y-tunnus: 2122839-7</p>
                      <p>Innova 2</p>
                      <p>Lutakonaukio 7</p>
                      <p>40100 Jyväskylä</p>
                      <p><a href="https://www.paytrail.com/kuluttaja/tietoa-maksamisesta" target="_blank">paytrail.com/kuluttaja/tietoa-maksamisesta</a></p>
                    </li>
                    <li>
                      Laskulla maksettaessa maksuehto on 14 päivää netto. Viivästyneestä suorituksesta peritään
                      viivästyskorkoa korkolain mukaisesti.
                    </li>
                  </ol>
                </li>
                <li>
                  Toimitus
                  <ol>
                    <li>
                      Tilaukset käsitellään ja painetaan viimeistään 2 viikon kuluessa tilauksen vahvistamista seuraavasta
                      arkipäivästä, jonka jälkeen tilatut tuotteet luovutetaan kuljetusyhtiön toimitettavaksi asiakkaan
                      antamaan toimitusosoitteeseen.
                    </li>
                    <li>
                      Custom Fazer -tuotteet painetaan, pakataan ja lähetetään yhteistyökumppanimme varastoon Inkeroisiin,
                      josta ne toimitetaan Asiakkaalle.
                    </li>
                    <li>
                      Tuotteet toimitetaan Asiakkaan ilmoittamaan toimitusosoitteeseen. Asiakas vastaa toimitusosoitteen
                      oikeellisuudesta. Toimitusaika on arviolta n. 2-3 viikkoa tilauksen vahvistamisesta seuraavasta
                      arkipäivästä. Fazer pyrkii parhaansa mukaan arvioituun toimitusaikaan mutta ei vastaa viivästyksistä.
                    </li>
                    <li>
                      Toimitusalue on Manner-Suomi. Ulkomaan (EU-alue) toimitukset ja useaan toimipaikkaan toimitettavat
                      toimitukset käsitellään tapauskohtaisesti. Emme toimita Custom Fazer -palvelun tuotteita Kreikkaan,
                      Romaniaan, Bulgariaan, Kanarian Saarille tai Ahvenanmaalle.
                    </li>
                  </ol>
                </li>

                <li>
                  Reklamaatiot
                  <ol>
                    <li>
                      Asiakkaiden tulee tarkistaa toimitus heti toimituksen vastaanotettuaan. Mikäli Asiakas epäilee
                      tuotteessa olevan virheen tai toimituksesta puuttuvan tuotteita, tulee Asiakkaan esittää
                      toimituksiin liittyvät reklamaatiot Fazerille välittömästi tai viimeistään 3 päivän kuluessa
                      tuotteen vastaanottamisesta. Mikäli tuotteessa todetaan olleen virhe, Fazer korjaa virheen parhaaksi
                      katsomallaan tavalla i) toimittamalla virheettömän tai korvaavan tuotteen virheellisen tilalle,
                      tai ii) purkamalla kyseisen tuotteen kauppa ja hyvittämällä kauppahinta Asiakkaalle.
                    </li>
                    <li>
                      Asiakkaalla ei ole reklamaatio-oikeutta sellaisen tuotevirheen osalta, joka on johtunut Asiakkaan
                      Palveluun lataaman kuvamateriaalin huonosta laadusta tai tapauksessa, jossa Fazer on toimittanut
                      tuotteen Asiakkaan ohjeiden mukaisesti osoitteeseen, jonka olosuhteet eivät täytä tuotteen
                      säilyvyydelle asetettuja suosituksia (esim. tuotteen toimitus postilaatikkoon kesällä). Asiakkaan
                      vastuulla on siirtää tuote pikimmiten toimituksen jälkeen asianmukaisiin säilytystiloihin.
                    </li>
                  </ol>
                </li>
                <li>
                  Immateriaalioikeudet
                  <ol>
                    <li>
                      Palvelun sisällön kaikki oikeudet kuuluvat Fazerille tai sen konserniyhtiöille. Sivustolla oleva
                      materiaali (kuten tekstit, kuvat, logot, tavaramerkit ja muu suojattu aineisto) on Fazerin tai sen
                      konserniyhtiöiden omaisuutta. Materiaalien kopioiminen tai käyttö ilman Fazerin lupaa on kielletty.
                    </li>
                    <li>
                      Asiakas vakuuttaa, että hänen suunnittelemaansa tuotetta varten toimittama materiaali tai sisältö
                      ei loukkaa hyviä tapoja tai kolmansien oikeuksia. Asiakas vastaa Fazerille kaikista vahingoista,
                      joita Fazerille aiheutuu Asiakkaan toimittamasta materiaalista tai sisällöstä johtuen taikka siitä,
                      että se loukkaa hyvää tapaa tai kolmannen oikeuksia. Fazer pidättää itsellään oikeuden olla
                      hyväksymättä tai toimittamatta tilausta perustellusta syystä, kuten jos suunniteltu pakkaus on
                      Fazerin mielestä hyvien tapojen vastainen.
                    </li>
                  </ol>
                </li>
                <li>
                  Vastuunrajoitus
                  <ol>
                    <li>
                      Fazer ei takaa Palvelun häiriötöntä toimintaa. Palvelua koskevat samat rajoitukset kuin Internetiä
                      yleensä, esimerkiksi käyttäjien yhtäaikaiset yhteydenotot voivat ruuhkauttaa Palvelua ja vaikuttaa
                      sen käytettävyyteen. Fazer ei vastaa siitä, jos Asiakkaan tilaus ei tule perille tietoverkon tai
                      tietoliikenneyhteyksien toimimattomuuden vuoksi. Fazer ei vastaa ylivoimaisen esteen (Force majeure)
                      aiheuttamista viivästymisistä. Lisäksi Fazer ei ole vastuussa kuljetuksissa tapahtuvista virheistä.
                    </li>
                    <li>
                      Fazer ei vastaa välillisistä vahingoista. Fazerin vastuu rajoittuu kaikissa tapauksissa
                      virheellisten/viivästyneiden/puuttuneiden tuotteiden arvoon.
                    </li>
                  </ol>
                </li>
                <li>
                  Sovellettava laki ja riitojen ratkaisu
                  <ol>
                    <li>
                      Mahdollisiin Asiakkaan ja Fazerin välisiin riita-asioihin sovelletaan Suomen lakia ja niitä koskevat
                      riidat ratkaistaan Helsingin käräjäoikeudessa.
                    </li>
                  </ol>
                </li>
                <li>
                  Palveluntarjoajan tiedot
                  <ol>
                    <li>Fazer Makeiset Oy, Y-tunnus 0728786-8, Fazerintie 6, 01230 Vantaa</li>
                  </ol>
                </li>
                <li>
                  Yhteystiedot
                  <ol>
                    <li>
                      Saat meihin yhteyden sähköpostitse:
                      <a href="mailto:yritysmyynti@fazer.com">yritysmyynti@fazer.com </a>.
                    </li>
                  </ol>
                </li>
              </ol>

              <p>
                <a href="https://www.fazer.com" target="_blank">www.fazer.com</a>
              </p>

              <button
                class="upload-button button is-primary mt-6"
                @click="$router.push({name: 'Home'})"
              >
                <span>Takaisin</span>
              </button>
            </div>
            <div class="content is-medium" v-else>
              <h1 class="has-text-centered">
                Fazer Oma kalenteri -palvelun sopimus- ja toimitusehdot
              </h1>
              <p class="has-text-centered">
                Katso myös Fazerin verkkosivustojen yleiset
                <a href="https://www.fazergroup.com/fi/kayttoehdot/" target="_blank">käyttöehdot</a>,
                sovelletaan Fazer Oma kalenteri -palvelun ja verkkosivuston käyttöön.
              </p>
              <ol>
                <li>
                  Yleistä
                  <ol>
                    <li>
                      Fazer Oma kalenteri -palvelu on Fazer Makeiset Oy:n (jäljempänä “Fazer”) elintarvikkeiden
                      pakkaussuunnittelu- ja tilauspalvelu (jäljempänä ”Palvelu”).
                    </li>
                    <li>
                      Nämä sopimusehdot sisältävät Palvelun käyttöä koskevat ehdot sekä tilausta ja toimitusta koskevat
                      ehdot. Rekisteröityessään Palveluun tai ostaessaan tuotteita Palvelusta Palvelun käyttäjä
                      (jäljempänä ”Asiakas”) hyväksyy ehdot ja sitoutuu noudattamaan näitä ehtoja. Palvelussa on näitä
                      ehtoja tarkentavia ohjeita.
                    </li>
                    <li>
                      Palvelu on ensisijaisesti tarkoitettu kuluttaja-asiakkaille. Myös yritysasiakkaat voivat tehdä
                      tilauksen palvelussa, mutta maksutapana on vain korttimaksu.
                    </li>
                  </ol>
                </li>
                <li>
                  Henkilötiedot ja niiden käyttö
                  <ol>
                    <li>
                      Palvelua käyttävien Asiakkaiden henkilö- ja yritystiedot kerätään ja tallennetaan Fazerin
                      ylläpitämään asiakasrekisteriin. Henkilötietojen käyttö on kuvattu Fazerin <a href="https://www.fazergroup.com/privacy/c72ba004-34e9-426d-800b-d1731fe03f27" target="_blank">tietosuojaselosteessa</a>.
                      Henkilötietoja kerätään pääasiassa Palvelun toteuttamiseksi sekä tuotteiden toimittamiseksi.
                      Hyödynnämme tekoälyä automaattisessa kuvientunnistuksessa tarkoituksenamme tehostaa hyvän tavan
                      vastaisen sisällön tunnistamista. Emme hyödynnä toiminnassa automaattista päätöksentekoa, vaan
                      kaikki tulokset varmistetaan manuaalisesti.
                    </li>
                    <li>
                      Asiakkaalla on oikeus tarkistaa itseään koskevat henkilötiedot sekä pyytää muuttamaan niitä tai
                      poistamaan tiedot rekisteristä kokonaan.
                    </li>
                  </ol>
                </li>

                <li>
                  Tilaus
                  <ol>
                    <li>Itse suunnitellut tuotteet tilataan Fazer Oma kalenteri -palvelun välityksellä.</li>
                    <li>
                      Tilaus tulee Fazeria sitovaksi, kun se on erikseen Fazerin toimesta vahvistettu. Fazer pidättää
                      itsellään oikeuden olla hyväksymättä tilausta esimerkiksi siinä tapauksessa, että tilaus on
                      Fazerin mielestä hyvien tapojen vastainen tai muuten sopimaton tai jos Fazerilla on syytä epäillä
                      Asiakkaan maksukykyä. Fazer voi käyttää tätä oikeutta myös tilausvahvistuksen toimittamisen
                      jälkeen. Fazer pyrkii ilmoittamaan Asiakkaalle mahdollisimman pian siinä tapauksessa, että Fazer
                      hylkää tilauksen. Asiakkaalla ei ole oikeutta peruuttaa tilausta sen jälkeen, kun tuotanto on
                      aloitettu.
                    </li>
                  </ol>
                </li>
                <li>
                  Tuotehinnat
                  <ol>
                    <li>
                      Tuotteiden kulloinkin voimassa olevat hinnat näkyvät Fazer Oma kalenteri -palvelun sivuilla.
                      Toimituskulut lisätään tilaukselle valitun toimitustavan mukaisesti. Tuotteiden hinnat ja
                      toimituskulut sisältävät arvonlisäveron 14%. Mikäli arvonlisäverokantaa muutetaan lainsäädännön
                      tai viranomaisohjeistuksen mukaisesti, on Fazerilla oikeus muuttaa arvonlisävero vastaamaan
                      muuttunutta verokantaa sen voimaantulopäivästä.
                    </li>
                  </ol>
                </li>
                <li>
                  Maksaminen
                  <ol>
                    <li>
                      Asiakas voi maksaa verkkokaupassamme yleisimmillä verkkomaksutavoilla, kuten pankkimaksuilla sekä
                      luottokortilla (Visa, Mastercard). Kaikki hinnat sisältävät ALV:n ja käytettynä valuuttana on euro.
                    </li>
                    <li>
                      Verkkomaksutavoilla maksaessa maksupalvelutarjoajana toimii Paytrail. Paytrail on turvallinen ja
                      helppo maksutapa. Kun valitset maksutavaksi ”Maksa verkossa”, sinut ohjataan Paytrail-maksuseinälle,
                      mistä sinut ohjataan onnistuneen maksusuorituksen jälkeen takaisin Fazer Oma kalenteri -palveluun.
                      Kun maksat Paytrail tarjoamilla maksutavoilla, hyväksyt Paytrailin ehdot.
                      <p style="margin-top: 15px;">
                      Maksupalvelutarjoaja
                      </p>
                      <p style="margin-top: 15px;">
                      Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana toimii Paytrail Oyj (2122839-7)
                        yhteistyössä suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana
                        tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on
                        maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä
                        tuotteen toimittajaan.
                      </p>

                      <p style="margin-top: 15px;">Paytrail Oyj, y-tunnus: 2122839-7</p>
                      <p>Innova 2</p>
                      <p>Lutakonaukio 7</p>
                      <p>40100 Jyväskylä</p>
                      <p><a href="https://www.paytrail.com/kuluttaja/tietoa-maksamisesta" target="_blank">paytrail.com/kuluttaja/tietoa-maksamisesta</a></p>
                    </li>
                  </ol>
                </li>
                <li>
                  Toimitus
                  <ol>
                    <li>
                      Tilaukset käsitellään ja painetaan viimeistään 2 viikon kuluessa tilauksen vahvistamista
                      seuraavasta arkipäivästä, jonka jälkeen tilatut tuotteet luovutetaan kuljetusyhtiön toimitettavaksi
                      asiakkaan antamaan toimitusosoitteeseen.
                    </li>
                    <li>
                      Fazer Oma kalenteri -tuotteet painetaan, pakataan ja lähetetään yhteistyökumppanimme varastoon
                      Inkeroisiin, josta ne toimitetaan Asiakkaalle.
                    </li>
                    <li>
                      Tuotteet toimitetaan Asiakkaan ilmoittamaan toimitusosoitteeseen. Asiakas vastaa toimitusosoitteen
                      oikeellisuudesta. Toimitusaika on arviolta n. 2-3 viikkoa tilauksen vahvistamisesta seuraavasta
                      arkipäivästä. Fazer pyrkii parhaansa mukaan arvioituun toimitusaikaan mutta ei vastaa viivästyksistä.
                    </li>
                    <li>
                      Toimitusalue on Manner-Suomi.
                    </li>
                  </ol>
                </li>

                <li>
                  Reklamaatiot
                  <ol>
                    <li>
                      Asiakkaiden tulee tarkistaa toimitus heti toimituksen vastaanotettuaan. Mikäli Asiakas epäilee
                      tuotteessa olevan virheen tai toimituksesta puuttuvan tuotteita, tulee Asiakkaan esittää
                      toimituksiin liittyvät reklamaatiot Fazerille välittömästi tai viimeistään 3 päivän kuluessa
                      tuotteen vastaanottamisesta. Mikäli tuotteessa todetaan olleen virhe, Fazer korjaa virheen
                      parhaaksi katsomallaan tavalla i) toimittamalla virheettömän tai korvaavan tuotteen virheellisen
                      tilalle, tai ii) purkamalla kyseisen tuotteen kauppa ja hyvittämällä kauppahinta Asiakkaalle.
                    </li>
                    <li>
                      Asiakkaalla ei ole reklamaatio-oikeutta sellaisen tuotevirheen osalta, joka on johtunut Asiakkaan
                      Palveluun lataaman kuvamateriaalin huonosta laadusta tai tapauksessa, jossa Fazer on toimittanut
                      tuotteen Asiakkaan ohjeiden mukaisesti osoitteeseen, jonka olosuhteet eivät täytä tuotteen
                      säilyvyydelle asetettuja suosituksia (esim. tuotteen toimitus postilaatikkoon kesällä). Asiakkaan
                      vastuulla on siirtää tuote pikimmiten toimituksen jälkeen asianmukaisiin säilytystiloihin.
                    </li>
                  </ol>
                </li>
                <li>
                  Immateriaalioikeudet
                  <ol>
                    <li>
                      Palvelun sisällön kaikki oikeudet kuuluvat Fazerille tai sen konserniyhtiöille. Sivustolla oleva
                      materiaali (kuten tekstit, kuvat, logot, tavaramerkit ja muu suojattu aineisto) on Fazerin tai
                      sen konserniyhtiöiden omaisuutta. Materiaalien kopioiminen tai käyttö ilman Fazerin lupaa on
                      kielletty.
                    </li>
                    <li>
                      Asiakas vakuuttaa, että hänen suunnittelemaansa tuotetta varten toimittama materiaali tai sisältö
                      ei loukkaa hyviä tapoja tai kolmansien oikeuksia. Asiakas vastaa Fazerille kaikista vahingoista,
                      joita Fazerille aiheutuu Asiakkaan toimittamasta materiaalista tai sisällöstä johtuen taikka
                      siitä, että se loukkaa hyvää tapaa tai kolmannen oikeuksia. Fazer pidättää itsellään oikeuden
                      olla hyväksymättä tai toimittamatta tilausta perustellusta syystä, kuten jos suunniteltu pakkaus
                      on Fazerin mielestä hyvän tavan vastainen.
                    </li>
                    <li>
                      Fazer Oma kalenteri -palvelu on tarkoitettu myönteisten, iloisten ja inspiroivien tuotteiden luomiseen.
                      Emme hyväksy palvelun kautta lähetettävissä sisällöissä kiroilua, rasistista kielenkäyttöä tai
                      muuta hyvän tavan vastaista tai Fazerin eettisten periaatteiden vastaista kirjoitusta tai
                      kuva-aineistoa. Lue Fazerin eettiset periaatteet <a href="https://www.fazergroup.com/fi/vastuullisuus2/ihmiset-ja-hyvinvointi/fazerin-eettiset-ohjeet/" target="_blank">täältä</a>.
                      Hyödynnämme kuvien tarkastuksessa automaattista kuvantunnistusta, joka havaitsee kuvien sisällöstä
                      aiheita, joiden sisältöä emme hyväksy tuotteisiin. Näitä ovat esimerkiksi alastomuus, väkivalta,
                      visuaalisesti häiritsevä, päihteet, alkoholi, töykeät eleet, uhkapeli tai vihasymbolit.
                    </li>
                  </ol>
                </li>
                <li>
                  Vastuunrajoitus
                  <ol>
                    <li>
                      Fazer ei takaa Palvelun häiriötöntä toimintaa. Palvelua koskevat samat rajoitukset kuin Internetiä
                      yleensä, esimerkiksi käyttäjien yhtäaikaiset yhteydenotot voivat ruuhkauttaa Palvelua ja vaikuttaa
                      sen käytettävyyteen. Fazer ei vastaa siitä, jos Asiakkaan tilaus ei tule perille tietoverkon tai
                      tietoliikenneyhteyksien toimimattomuuden vuoksi. Fazer ei vastaa ylivoimaisen esteen (Force majeure)
                      aiheuttamista viivästymisistä. Lisäksi Fazer ei ole vastuussa kuljetuksissa tapahtuvista virheistä.
                    </li>
                    <li>
                      Fazer ei vastaa välillisistä vahingoista. Fazerin vastuu rajoittuu kaikissa tapauksissa
                      virheellisten/viivästyneiden/puuttuneiden tuotteiden arvoon.
                    </li>
                  </ol>
                </li>
                <li>
                  Sovellettava laki ja riitojen ratkaisu
                  <ol>
                    <li>
                      Mahdollisiin Asiakkaan ja Fazerin välisiin riita-asioihin sovelletaan Suomen lakia ja niitä
                      koskevat riidat ratkaistaan Helsingin käräjäoikeudessa.
                    </li>
                  </ol>
                </li>
                <li>
                  Palveluntarjoajan tiedot
                  <ol>
                    <li>Fazer Makeiset Oy, Y-tunnus 0728786-8, Fazerintie 6, 01230 Vantaa</li>
                  </ol>
                </li>
                <li>
                  Yhteystiedot
                  <ol>
                    <li>
                      Saat meihin yhteyden sähköpostitse:
                      <a href="mailto:fazerstore@fazer.com">fazerstore@fazer.com </a>.
                    </li>
                  </ol>
                </li>
              </ol>

              <p>
                <a href="https://www.fazer.com" target="_blank">www.fazer.com</a>
              </p>

              <button
                class="upload-button button is-primary mt-6"
                @click="$router.push({name: 'Home'})"
              >
                <span>Takaisin</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import Footer from '@/components/Footer'
import { useMeta } from 'vue-meta'
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'RulesPage.vue',
  components: {
    SectionHeader,
    Footer
  },
  methods: {
    ...mapMutations([
      'setSpinnerState'
    ]),
    ...mapActions([
      'getProducts'
    ]),
     ...mapGetters({
       getCalendarMode: 'getCalendarMode',
     }),
  },
  mounted() {
    if (!this.getCalendarMode()) {
      useMeta({
        title: 'Custom Fazer -palvelun sopimus- ja toimitusehdot – Custom Fazer',
        description: 'Tutustu Custom Fazer -palvelun sopimus ja toimitusehtoihin. Palvelu palvelee sekä kuluttaja- että yritysasiakkaita.'
      })
    } else {
      useMeta({
        title: 'Tilausehdot – Fazer Oma kalenteri',
        description:
          'Tutustu Fazer Oma kalenteri -palvelun sopimus ja toimitusehtoihin. Oma kalenteri palvelussa voit suunnitella oman suklaakalenterin.',
      })
    }
    this.getProducts()
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/mybulma';

h1 {
  color: $primary !important;
  font-size: 45px !important;
}


ol {
  counter-reset: item;
  margin-bottom: 2rem;
}

li {
  display: block;
  font-weight: bold;

  li{
    font-weight: normal;
  }
  p {
    color: rgb(0, 0, 128);
    font-size: 20px;
    margin-bottom: 0px !important;
  }
}

li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
  font-weight: bold;
}

.content {
  padding: 20px;
}

button {
  span {
    font-size: 20px;
  }
}

a {
  font-weight: bold;
}
</style>
